/* GLOBAL VARS */

/* Colors */

$blue: #007bff;
$blue-light: #98caff;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #28a745;
$teal: #20c997;
$teal-light: #42ffc6;
$cyan: #17a2b8;

$white: #fff;
$white-dark: #f8f9fa;
$white-transparent: rgba(255, 255, 255, 0.125);
$gray-ultra-light: #e9ecef;
$gray-transparent: rgba(0, 0, 0, 0.125);
$gray-light: #dee2e6;
$gray: #6c757d;
$gray-dark: #343a40;
$black: #000;
$black-transparent: rgba(0, 0, 0, 0.1);

$light: $white-dark;
$dark: $gray-dark;

$primary: $blue;
$secondary: $gray;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
