@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700');
@import 'variables.scss';

/* Common */

*:focus {
  outline: none;
}

html {
  font-size: 14px;
}

body {
  background-color: $light;
  font-family: 'Open Sans', sans-serif;
  max-zoom: 120%;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

/* Forms */

.form-control {
  color: $gray-dark;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  padding: 0.3rem 0.35rem 0.3rem;

  &.dx-editor-underlined {
    border: 0 !important;
    box-shadow: none !important;
  }

  &:hover:not(.is-invalid) {
    border-color: rgba($blue, 0.7);
  }
}

.form-control-xxs {
  font-size: 0.9rem;
  line-height: 1.1rem;
  height: calc(1rem + 8px);
  padding: 0.1rem 0.3rem;
}

.form-control-lg {
  padding: 0.5rem 1rem;
}

.form-control-xl {
  font-size: 1.5rem;
  line-height: 1.75rem;
  height: calc(4rem + 4px);
  padding: 0.8rem 1.3rem;
}

.form-control-xxl {
  font-size: 3rem;
  line-height: 2.15;
  height: calc(7rem + 2px);
  padding: 1.8rem;
}

.form-group.row {
  & > * {
    align-items: center;
  }
}

.no-wrap {
  white-space: nowrap;
}

.visibility-hidden {
  visibility: hidden;
}

.cursor-pointer {
  cursor: pointer;
}

.input-group {
  align-items: stretch !important;

  .input-group-prepend,
  .input-group-append {
    height: calc(1.5em + 0.75rem + 2px);

    &.currency {
      width: 80px;
    }

    .input-group-text {
      padding-left: 0.4rem;
      padding-right: 0.4rem;
    }

    .dx-texteditor.dx-editor-outlined {
      background-color: $gray-ultra-light;
      padding: 0;

      &.is-invalid {
        background-color: lighten($danger, 10%);
        border-color: $danger;

        .dx-placeholder {
          color: $white;
        }
      }
    }
  }

  .input-group-prepend {
    .dx-texteditor.dx-editor-outlined {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .input-group-append {
    .dx-texteditor.dx-editor-outlined {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  &.input-group-sm, &.input-group-xxs {
    .input-group-append, .input-group-prepend {
      height: auto;
    }
  }
}

.col-form-label-sm {
  line-height: 1.75;
}

@media (max-width: 767.98px) {
  label.col-form-label.text-right {
    text-align: left !important;
  }
}

.text-sm {
  font-size: .8rem !important;
}

.text-lg {
  font-size: 1.2rem !important;
}

.text-xl {
  font-size: 1.8rem !important;
}

.text-xxl {
  font-size: 3rem !important;
}

.text-xxxl {
  font-size: 5rem !important;
}

.height-0 {
  height: 0 !important;
}

.height-100 {
  height: 100% !important;
}

.min-height-100 {
  min-height: 100% !important;
}

/* DevExtreme */

.dx-texteditor {
  &:not([stylingmode='underlined']) {
    border: 1px solid $gray-light;
  }

  &.dx-state-focused,
  &.dx-state-active {
    outline: 0;
    border-color: $blue;

    &:not([stylingmode='underlined']) {
      -webkit-box-shadow: 0 0 0 0.2rem rgba($blue, 0.25);
      box-shadow: 0 0 0 0.2rem rgba($blue, 0.25);
    }

    &.is-invalid {
      &:not([stylingmode='underlined']) {
        -webkit-box-shadow: 0 0 0 0.2rem rgba($red, 0.25);
        box-shadow: 0 0 0 0.2rem rgba($red, 0.25);
      }
    }
  }

  &.dx-state-disabled {
    background-color: $gray-ultra-light;
    opacity: 1;
  }

  &.is-invalid {
    border-color: $red;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }

  &.form-control-lg {
    .dx-textbox {
      padding: 0;
    }

    .dx-placeholder {
      padding: .4rem .8rem;
    }

    .dx-texteditor-input {
      padding: .4rem .8rem;
      font-size: 1.25rem;
      line-height: 1.5;
    }
  }
}

.dx-placeholder {
  color: $gray;
}

.dx-editor-cell {
  .dx-texteditor {
    &.dx-state-focused {
      box-shadow: none;
    }
  }
}

.dx-dropdowneditor {
  padding: 0;
}

.dx-selectbox,
.dx-datebox {
  &.dx-state-disabled.dx-widget,
  &.dx-state-disabled .dx-widget {
    background-color: $gray-ultra-light !important;
    opacity: 1 !important;

    .dx-texteditor-input {
      background-color: $gray-ultra-light !important;
    }
  }

  .dx-textbox {
    &.dx-state-focused {
      box-shadow: none !important;
    }
  }

  &:not(.form-control-lg) {
    .dx-textbox {
      padding: 2px;
    }
  }
}

.dx-texteditor-input {
  color: $gray-dark;
}

.dx-widget {
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;

  input,
  textarea {
    font-family: 'Open Sans', sans-serif;
    line-height: 1.5;
  }
}

.dx-tagbox {
  height: auto !important;
  min-height: calc(2.25rem + 2px);
}

.dx-context-menu {
  .dx-submenu {
    border-radius: 0.2rem;

    .dx-menu-items-container {
      .dx-menu-item-wrapper {
        .dx-menu-item {
          .dx-menu-item-content {
            padding: 0.6rem 0.9rem;
            font-size: 0.9rem;

            .icon {
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
}

.dx-datagrid-filter-row
  .dx-editor-cell
  .dx-editor-with-menu
  .dx-texteditor-input {
  border-radius: 0;
}

.dx-popup-wrapper > .dx-overlay-content {
  border-radius: 0;
}

.dx-datagrid-column-chooser {
  height: 100% !important;
  width: 100% !important;
  transform: none !important;

  & > .dx-overlay-content {
    bottom: 0;
    height: 300px !important;
    width: 280px !important;
    margin: auto !important;
    right: 0;
    transform: none !important;

    .dx-popup-content {
      .dx-scrollable {
        min-height: 250px !important;
      }
    }
  }
}

/* .dx-header-filter-menu {
  .dx-overlay-content {
    .dx-list {
      .dx-list-select-all {
        display: none;
      }

      .dx-list-item:nth-child(3) {
        border-top: 0;
      }
    }
  }
} */

.dx-datagrid-headers {
  background-color: $white;
  top: -15px;
  position: sticky;
  z-index: 1;
}

.dx-datagrid-rowsview {
  z-index: 0;
}

.dx-datagrid-pager {
  background-color: $white;
  bottom: -15px;
  position: sticky;
  z-index: 1;
}

.dxc-tooltip {
  z-index: 10000;
}

/* Drop down menu */

.dropdown-menu {
  .dropdown-item {
    cursor: pointer;
    padding: 0.5rem 1.75rem 0.5rem 1.25rem;

    i {
      margin-right: 10px;
      text-align: center;
      width: 20px;
    }
  }
}

/* Button */

.btn-xs {
  padding: 0.2rem 0.3rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-xxs {
  padding: 0.1rem 0.3rem;
  font-size: 0.85rem;
  line-height: 1.1;
  border-radius: 0.2rem;
}

.btn-xl {
  border-radius: 0.3rem;
  font-size: 1.5rem;
  line-height: 1.75;
  padding: 0.8rem 1.3rem;
}

.btn-xxl {
  font-size: 3rem;
  line-height: 1;
  padding: 1.8rem;
}

.btn-full {
  border-radius: 0;
  height: 100%;
  width: 100%;
}

.btn-group {
  & > .btn.btn-primary:not(.active) {
    background-color: $white;
    color: $blue;
  }
}

.btn-lg.btn-icon {
  padding: 0.33rem 1rem;
}

.btn-y-block {
  height: 100%;
}

.btn-type-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.btn-type {
  display: block;
  flex-direction: column;
  align-content: center;
  font-size: 0.9rem;
  font-weight: bold;
  justify-content: space-between;
  width: 120px;
  margin: 10px;
  overflow: hidden;
  text-overflow: ellipsis;

  .logo {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    height: 100px;
  }

  .icon {
    font-size: 4rem;
    display: block;
    line-height: 100px;
  }
}

.btn-choice {
  align-items: center;
  display: flex;
  flex-direction: row;

  .icon {
    margin-right: 1rem;
  }

  .data {
    .label {
      margin: 0 !important;
    }
  }
}

/* Colors */

.bg-red {
  background-color: $red !important;
}

.bg-orange {
  background-color: $orange !important;
}

.bg-purple {
  background-color: $purple !important;
}

.bg-blue {
  background-color: $blue !important;
}

.bg-tranparent {
  background-color: transparent !important;
}

.bg-blue-light {
  background-color: $blue-light !important;
}

.bg-teal {
  background-color: $teal !important;
}

.bg-teal-light {
  background-color: $teal-light !important;
}

.bg-cyan {
  background-color: $cyan !important;
}

.bg-pink {
  background-color: $pink !important;
}

/* Popover */

.popover {
  max-width: 650px;

  .popover-content {
    overflow-y: auto;
  }

  &.tools-popover {
    width: 250px;

    .popover-content {
      padding: 0;
    }
  }

  &.historic-popover,
  &.stock-popover {
    width: 350px;

    .popover-content {
      overflow: hidden;
    }
  }
}

/* List */

.dx-list {
  .dx-list-search {
    margin-bottom: 15px;
  }

  .dx-list-item {
    .dx-list-item-content {
      padding: 10px 10px;
    }

    &:not(:first-of-type) {
      border-top: 1px solid #ddd;
    }
  }

  &.sm {
    .dx-list-search {
      margin-bottom: 1rem;
    }

    .dx-list-item {
      .dx-list-item-content {
        padding: 3px 5px;
        font-size: 0.9rem;
      }
    }
  }

  &.handle-big {
    .dx-list-item {
      &.dx-state-focused {
        background-color: $gray-light;
        color: $dark;
      }

      .dx-list-reorder-handle-container {
        width: 30px;

        .dx-list-reorder-handle {
          height: 30px;
          width: 30px;
          font-size: 22px;
          line-height: 1;
        }
      }
    }
  }

  &.handle-left {
    .dx-list-item {

      align-items: center;
      display: flex;
      flex-direction: row-reverse;

      .dx-list-item-content {
        padding: 5px;
      }

      .dx-list-reorder-handle-container {
        height: 38px;
        display: flex;
        align-items: center;
      }

    }

  }

  &.full-handle {
    .dx-list-item {
      position: relative;

      .dx-list-item-after-bag {
        height: 100%;
        left: 0;
        position: absolute;
        width: 100%;

        .dx-list-reorder-handle {
          height: 100%;
          width: 100%;

          &::before {
            display: none;
          }
        }

        .dx-list-static-delete-button {
          bottom: 10px;
          margin: auto;
          position: absolute;
          right: 10px;
          top: 10px;
        }
      }
    }
  }

  .dx-empty-message {
    text-align: center;
  }
}

/* Datagrid */

.dx-datagrid {

  .dx-datagrid-header-panel {
    display: none;
  }

  .dx-datagrid-headers {
    color: $gray-dark;
    font-weight: bold;
    font-size: 1rem;
  }

  .dx-datagrid-rowsview {
    .dx-datagrid-content {
      .dx-datagrid-table {
        .dx-row {
          /* Spacing */

          & > td {
            font-size: 0.8rem;
            height: 30px;
            vertical-align: middle;

            .btn {
              font-size: inherit;
            }

            &:not(.dx-master-detail-cell) {
              padding: 1px 5px;
            }

            &.dx-validator {
              overflow: visible;

              .dx-datagrid-revert-tooltip,
              .dx-invalid-message {
                display: none;
              }

              &.dx-datagrid-invalid {
                .dx-datagrid-revert-tooltip,
                .dx-invalid-message {
                  display: block;
                }
              }
            }

            &.editable {
              cursor: pointer;
              padding-left: 25px;
              position: relative;

              .dx-highlight-outline {
                padding: 0;
              }

              .dx-highlight-outline::after {
                border: 0;
              }

              &::before {
                font-family: 'Font Awesome 5 Pro';
                font-style: normal;
                font-weight: 800;
                line-height: 1;
                content: '\f044';
                position: absolute;
                left: 5px;
                color: $gray-light;
                top: 50%;
                transform: translateY(-50%) rotate3d(0, 1, 0, 180deg);
              }

              &.dx-focused {
                &::before {
                  color: $gray;
                }
              }
            }
          }

          /* Status */

          &.dx-state-hover:not(.dx-header-row) {
            & > td {
              background-color: $gray-ultra-light !important;
            }
          }

          &.splitted {
            & > td {
              background: lighten($info, 40%);
            }

            &.dx-state-hover {
              & > td {
                background: lighten($info, 30%) !important;
              }
            }
          }

          &.disabled {
            & > td {
              opacity: 0.5;
            }
          }

          &.alert {
            & > td {
              background: lighten($warning, 20%);
            }

            &.dx-state-hover {
              & > td {
                background: lighten($warning, 10%) !important;
              }
            }
          }
        }

        .btn-group {
          .dropdown-toggle::after {
            display: none;
          }
        }
      }
    }

    &.dx-empty {
      .dx-scrollable-container {
        overflow: hidden;
      }
    }
  }
}

.dx-widget {
  &.initializing {
    .dx-datagrid {
        visibility: hidden;
        height: 0;
      }
  }

  &.grid-error, &.empty {
      .dx-datagrid {
        .dx-datagrid-rowsview,
        .dx-datagrid-pager {
          visibility: hidden;
          height: 0;
        }
      }
    }
}

.dx-datagrid-table {
  .btn-action {
    padding: 3px;

    .icon {
      font-size: 1.1rem;
      line-height: 1rem;

      &.fa-times {
        font-size: 1.4rem;
      }
    }

  }

  .icon-edit,
  .icon-action {
    font-size: 1rem;

    &.lg {
      font-size: 1.1rem;
      line-height: 1.4;
    }
  }

  .icon-delete {
    font-size: 1.4rem;
  }
}

.dx-datagrid-rowsview.dx-scrollable-scrollbars-alwaysvisible {
  .dx-scrollable-wrapper {
    .dx-scrollable-container {
      .dx-scrollable-content {
        padding-bottom: 20px;
      }

      .dx-scrollable-scrollbar.dx-scrollbar-horizontal.dx-scrollbar-hoverable {
        height: auto;

        .dx-scrollable-scroll {
          height: 15px;
        }

        &.dx-state-hover {
          .dx-scrollable-scroll {
            height: 20px;
          }
        }
      }
    }
  }

  &.dx-empty {
    .dx-scrollable-scrollbar {
      display: none;
    }
  }
}

.dx-widget {
  font-size: 1rem;
}

.loading .page-status {
  display: none;
}

.column-wrap {
  white-space: normal;
  word-wrap: break-word;
}

.overflow-visible {
  overflow: visible !important;
}

/* Tree list */

.dx-treelist {
  .icon-edit,
  .icon-action {
    font-size: 1rem;
  }

  .icon-delete {
    font-size: 1.4rem;
  }

  .dx-treelist-headers {
    color: $gray-dark;
    font-weight: bold;
    font-size: 1rem;
  }

  .dx-treelist-rowsview {
    .dx-treelist-content {
      .dx-treelist-table {
        .dx-row {
          /* Spacing */

          & > td {
            font-size: 0.8rem;
            height: 30px;
            vertical-align: middle;

            .btn {
              font-size: inherit;
            }

            &:not(.dx-master-detail-cell) {
              padding: 1px 5px;
            }

            &.dx-validator {
              overflow: visible;

              .dx-datagrid-revert-tooltip,
              .dx-invalid-message {
                display: none;
              }

              &.dx-datagrid-invalid {
                .dx-datagrid-revert-tooltip,
                .dx-invalid-message {
                  display: block;
                }
              }
            }
          }

          @for $i from 1 through 10 {
            &.dx-data-row[aria-level='#{$i}'] {
              td {
                background-color: darken($white, 0% + ($i * 8));
              }
            }
          }
        }
      }
    }
  }
}

/* Tree view */

.dx-treeview {
  .dx-treeview-node {
    padding-left: 20px;

    & > .dx-treeview-node-container {
      border-left: 1px solid #ccc;
    }

    .dx-treeview-toggle-item-visibility {
      left: -2px;
    }
  }
}

/* Tooltip */

.dxb-tooltip {
  z-index: 100;
}

/* Modal */

.dx-overlay-shader {
  background-color: rgba($black, 0.5);
}

.dx-popup-title.dx-toolbar {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid $gray-ultra-light;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;

  .dx-toolbar-item-content {
    font-size: 1.5rem;
    font-weight: bold;
  }
}

/* Modal */

.modal-lg {
  max-width: 900px;
}

.modal-xl,
.modal-xxl {
  margin-bottom: 0;
  margin-top: 0;

  .modal-body:not(.tabs) {
    max-height: calc(100vh - 125px);
    overflow-y: auto;
    overflow-x: hidden;
  }

  &.modal-header-lg {
    .modal-body:not(.tabs) {
      max-height: calc(100vh - 215px);
    }
  }
}

.modal-xl {
  max-width: 1450px;
}

.modal-xxl {
  max-width: 1600px;
}

.modal-full {
  height: 100vh;
  margin: 0;
  max-width: none;
  overflow: hidden;
  width: 100vw;

  .modal-content {
    border-radius: 0;
  }

  .modal-body {
    height: calc(100vh - 125px);
    overflow-y: auto;
    overflow-x: hidden;
  }
}

/* Tabs */

.tab-container {
  .nav-tabs {
    border-color: $gray-ultra-light;
    z-index: 10;

    @media (max-width: 767.98px) {
      .nav-link {
        font-size: 0.9rem;
      }
    }
  }

  &.centered {
    .nav-tabs {
      bottom: 100%;
      left: 0;
      position: absolute;
      flex-wrap: nowrap;
      justify-content: center;
      width: 100%;
    }
  }

  &.vertical {
    display: flex;
    flex-direction: row;
  }

  &.full-height {
    height: 100%;
  }

  &.inside {
    display: flex;
    flex-direction: column;

    .nav-tabs {
      position: relative;
      bottom: auto;
      justify-content: flex-start;
      white-space: nowrap;
      max-width: 100%;
      scrollbar-width: none;

      .nav-link {
        margin-bottom: -2px;
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &.hide-tabs {
    & > .nav-tabs {
      display: none;
    }
  }

  &.scrollable {
    .tab-content {
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

  .tab-content {
    flex: 1;
    height: 100%;
    max-width: 100%;
  }
}

/* Badges */

.badge-status,
.badge-tag,
.badge-class {
  font-size: 0.9rem;
  padding: 4px;

  &.badge-small {
    font-size: 0.7rem;
  }

  &.dismissable {
    padding-right: 17px;
  }

  .close {
    padding: 0;
    margin: 0 0 0 2px;
    height: 12px;

    span {
      display: block;
      line-height: 12px;
    }
  }
}

.dx-tag-content {
  background-color: $gray-dark;
  color: $white;
  border-radius: 0.25rem;
  font-weight: bold;
  font-size: 0.9rem;
  padding: 4px 17px 4px 4px;
}

.column-badges {
  padding-bottom: 2px !important;
  padding-top: 2px !important;

  .badge {
    margin: 2px;
  }
}

.badge-tooltip {
  border-radius: 100%;
  width: 18px;
  height: 18px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.badge-red {
  background-color: $red;
  color: $white;
}

.badge-orange {
  background-color: $orange;
  color: $white;
}

.badge-blue {
  background-color: $blue;
  color: $white;
}

.badge-black {
  background-color: $black;
  color: $white;
}

.badge-tranparent {
  background-color: $gray-light;
  color: $gray-dark;
}

.badge-drafted, .badge-waiting_replenishment {
  background-color: $gray;
  color: $white;
}

.badge-pending,
.badge-returning,
.badge-archived {
  background-color: $gray-light;
  color: $gray-dark;
}

.badge-confirmed,
.badge-available,
.badge-connected {
  background-color: $green;
  color: $white;
}

.badge-pl-assigned {
  background-color: $white;
  border: 1px solid $green;
  color: $green;
}

.badge-blocked,
.badge-dropshipped {
  background-color: $purple;
  color: $white;
}

.badge-error,
.badge-skipped,
.badge-failed {
  background-color: $red;
  color: $white;
}

.badge-canceled,
.badge-unavailable, .badge-replenishment_canceled {
  background-color: $red;
  color: $white;
}

.badge-deleted {
  background-color: $gray-dark;
  color: $white;
}

.badge-processed,
.badge-picked,
.badge-unknown,
.badge-received,
.badge-disconnected {
  background-color: $orange;
  color: $gray-dark;
}

.badge-shipped,
.badge-closed,
.badge-completed {
  background-color: $blue;
  color: $white;
}

.badge-packed {
  background-color: $cyan;
  color: $white;
}

.badge-printed {
  background-color: darken($cyan, 20%);
  color: $white;
}

.badge-processing,
.badge-partial,
.badge-assigned,
.badge-receiving,
.badge-running,
.badge-connecting {
  background-color: $yellow;
  color: $gray-dark;
}

.badge-delivered {
  background-color: $blue-light;
  color: $white;
}

.badge-parked {
  background-color: $teal;
  color: $gray-dark;
}

.badge-handed {
  background-color: $teal-light;
  color: $gray-dark;
}

.badge-header {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  font-size: 1rem;
}

.badge-double {
  display: inline-flex;
  font-size: 75%;
  font-weight: bold;
  overflow: hidden;
  padding-left: 7px;
  white-space: nowrap;

  .badge {
    font-size: 100%;
    margin-left: 5px;
    margin-right: -1px;
  }

  &.badge-switch {
    padding-right: 45px;
    position: relative;

    .dx-switch {
      height: 100%;
      position: absolute;
      padding: 0;
      right: 1px;
      margin: 0;
      top: -1px;
      width: 42px;
      
      .dx-switch-container {
        border: 0;
        border-left: 1px solid $blue;
        border-radius: 0;
        height: 100%;

        .dx-switch-on,
        .dx-switch-off {
          line-height: 20px;
          font-size: 11px;
        }

        .dx-switch-handle {
          height: 19px;
          margin-top: 0;
          margin-bottom: 0;
          position: relative;
          top: 1px;
        }

        .dx-switch-handle::before {
          background-color: $blue;
        }
      }
    }
  }

  &.badge-select {

    padding-left: 0;

    .badge {
      margin: 0 !important;
    }

  }
}

.badge-lg,
.badge-double-lg {
  font-size: 1rem;
}

.badge-xl,
.badge-double-xl {
  font-size: 1.8rem !important;
  padding: 6px;
}

.badge-xxl,
.badge-double-xxl {
  font-size: 3rem !important;
  padding: 10px;
}

/* Wizard */

wizard .steps-indicator li {
  font-family: sans-serif !important;
}

/* Table */

.table {
  &.table-fixed {
    table-layout: fixed;
  }

  tfoot > * {
    border-top: 2px solid $gray-light;
  }
}

/* HR */

hr {
  &.vertical {
    margin-bottom: 0;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 0;
    border: 0;
    border-right: 1px solid $black-transparent;
    height: 100%;
    width: 0;
  }
}

/* Card */

.card-grid {
  background-color: rgba(0, 0, 0, 0.03);
  font-weight: bold;

  .row {
    border-top: 1px solid rgba(0, 0, 0, 0.125);

    & > div {
      padding: 0.75rem 1.25rem;
    }
  }
}

/* Border */

.border-dashed {
  border-style: dashed !important;
}

.border-2 {
  border-width: 2px;
}

/* Box */

.box {
  margin-top: 10px;
  position: relative;

  .box-header {
    background: $white;
    left: 0;
    padding: 0 10px;
    position: absolute;
    top: -10px;
  }
}

/* Clipboard */

.clipboard {
  position: relative;

  &.outside {
    display: inline-block;
    margin-right: 10px;
  }

  .btn-clipboard {
    -webkit-transition: opacity 400ms ease-out;
    -moz-transition: opacity 400ms ease-out;
    -ms-transition: opacity 400ms ease-out;
    -o-transition: opacity 400ms ease-out;
    transition: opacity 400ms ease-out;
    padding: 0;
    opacity: 0.1;

    &.inside {
      margin-left: 5px;
      right: 0;
    }

    &.outside {
      position: absolute;
      left: calc(100% + 5px);
    }
  }

  &:hover {
    .btn-clipboard {
      opacity: 1;
    }
  }
}

/* Dropzone */

.dropzone {
  align-items: center;
  background: rgba($white, 0.8);
  border: 10px dashed $gray-light;
  color: $gray;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  &.file-over {
    border-color: $yellow;

    .step {
      opacity: 0.1;
    }
  }

  &.ready {
    border-color: $orange;
  }

  &.done {
    border-color: $green;
    color: $green;
  }

  &.error {
    border-color: $red;
    color: $red;
  }

  .step {
    text-align: center;
  }

  .upload-selection-input {
    display: none;
  }

  .icon,
  .text {
    color: inherit;
  }

  .title {
    margin-bottom: 1.5rem;
  }

  .icon {
    font-size: 5rem;
    margin-bottom: 1.5rem;
  }
}

// Opacity

.op-0 {
  opacity: 0;
}

.op-10 {
  opacity: 0.1;
}

.op-20 {
  opacity: 0.2;
}

.op-30 {
  opacity: 0.3;
}

.op-40 {
  opacity: 0.4;
}

.op-50 {
  opacity: 0.5;
}

.op-60 {
  opacity: 0.6;
}

.op-70 {
  opacity: 0.7;
}

.op-80 {
  opacity: 0.8;
}

.op-90 {
  opacity: 0.9;
}

.op-100 {
  opacity: 1;
}

// Height

.h-33 {
  height: 33%;
}

// Rotation

.rotate-90 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

.rotate-180 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.rotate-270 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
}

.flip-horizontal {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);
  -webkit-transform: scale(-1, 1);
  -moz-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.flip-vertical {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1);
  -webkit-transform: scale(1, -1);
  -moz-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  -o-transform: scale(1, -1);
  transform: scale(1, -1);
}

.flex-gap {
  gap: 1rem;
}

/* TOASTR BUGFIX */

#toast-container {
  & > div {
    opacity: 1;
    background-position: 20px ​center;
    background-size: 30px;
    border-radius: 0.3rem;
    padding-left: 60px;
    width: 400px;

    &:first-child {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}

page-content,
page-actions,
page-search,
tools-shoulder,
page-tools,
page-tools-shoulder,
page-shoulder-left,
page-shoulder-right,
modal-content,
modal-header,
op-main-menu {
  display: contents;
}

page-actions {
  .page-actions-info {
    position: absolute;
    top: 3px;
    color: $gray-dark;
    font-size: 0.75rem;
    right: 20px;

    &.low {
      top: 50px;
    }
  }

  .btn {
    min-width: 35px;
    white-space: nowrap;
  }

  .btn-group {
    .dropdown-toggle::after {
      display: none;
    }
  }
}

/* JSTREE */

.jstree {
  .jstree-container-ul {
    .jstree-node {
      .jstree-anchor {
        &.jstree-hovered,
        &.jstree-context {
          background: $gray-ultra-light;
          box-shadow: none;
        }

        &.jstree-search {
          color: $primary;
        }

        &.jstree-clicked {
          background: $secondary;
          color: $white;
          box-shadow: none;
        }
      }
    }
  }
}

/* VAKATA */

.vakata-context {
  border: 1px solid $gray-ultra-light;
  background: $white;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  padding: 1px;

  li {
    a {
      font-size: 0.9rem;
    }

    &.vakata-context-hover {
      a {
        background-color: $gray-ultra-light;
        box-shadow: none;
      }
    }
  }
}

/* CAROUSEL */

.carousel {
  .carousel-indicators {
    margin: 0;

    li {
      background-color: $gray;
    }
  }

  .carousel-inner {
    .item {
      .carousel-caption {
        bottom: auto;
        color: $gray;
        top: 0;
        left: 0;
        right: 0;
        margin: 0;
        padding: 0 5%;

        h4 {
          font-size: 1rem;
        }
      }

      img {
        max-width: 100%;
      }
    }
  }
}

@media (min-width: 992px) {
  .carousel {
    .carousel-inner {
      .item {
        .carousel-caption {
          h4 {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}